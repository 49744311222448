import React from 'react'

// mui
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

type Props = CircularProgressProps & {
  text?: string
}

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    width: '100%',
  },
})

const Spinner: React.FC<Props> = ({ size = 50, text, className, ...props }) => {
  const classes = useStyles()

  return (
    <div className={[classes.root, className].join(' ')}>
      <CircularProgress size={size} color="primary" {...props} />
      {text && <div>{text}</div>}
    </div>
  )
}

export default Spinner
