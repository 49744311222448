import { createMuiTheme } from '@material-ui/core'

export const COLORS = {
  red: '#ff4731',
  redDark: '#ff4731',

  grey: '#666',
  white: '#fff',
  green: '#299729',

  primary: '#131e29',
}

export const THEME = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.red,
      dark: COLORS.redDark,
      contrastText: COLORS.white,
    },
    background: {
      paper: COLORS.white,
      default: COLORS.red,
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        color: COLORS.grey,
      },
      colorPrimary: {
        color: COLORS.red,
      },
      colorSecondary: {
        color: COLORS.white,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: 'Averta-Regular',
      },
    },
    MuiTabs: {
      indicator: {
        height: '3px',
        backgroundColor: COLORS.red,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        marginBottom: 0,
        '& svg': {
          width: '30px',
          height: '25px',
        },
        '&$selected': {
          '& svg': {
            fill: COLORS.red,
          },
        },
      },
      wrapper: {
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'row',
      },
      labelIcon: {
        minHeight: '48px',
        paddingTop: '0',

        '& $wrapper > *:first-child': {
          marginBottom: 0,
          marginRight: 6,
        },
      },
    },
  },
  typography: {
    fontFamily: '"Averta-Regular","Helvetica Neue",Arial,sans-serif',
  },
})
