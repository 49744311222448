import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

// mui
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { THEME } from '@config/mui.config'

// components
import Spinner from 'components/common/Spinner/Spinner'

// components
const NotFound = lazy(() => import('components/core/NotFound'))
const Tickets = lazy(() => import('components/Tickets/Tickets'))

const useStyles = makeStyles({
  main: {
    maxWidth: '600px',
    padding: 10,
    margin: '0 auto',
  },
})

const App = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={THEME}>
      <main className={classes.main}>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" render={props => <Tickets {...props} />} />
              <Route render={() => <NotFound />} />
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </main>
    </ThemeProvider>
  )
}

export default App
